/**000
 * Created by ch01 on 23.07.15.
 */
(function($){
    $(document).ready(function(){
        $.fn.snbHelpers();
        $.fn.topMenuNavigation();
        $.fn.homeSlider(true); //autoplay
        $.fn.logoSlider();
        $.fn.groupTabs();
        $.fn.timeline();
        $.fn.sponsoringMembers(); 
        $.fn.cooperatingOrganizations();
        $.fn.snbStrcture();
        $.fn.googleMaps();
        $.fn.floatingStatutorySidebar();
        $.fn.adjustWindowHeight();
    });
})(jQuery);
