/**
 * Created by ch01 on 23.07.15.
 */
var snb = {};

(function ($) {
    $.fn.topMenuNavigation = function () {
        var navbar = $('.top-bar');
        snb.navTop = navbar.find('nav>ul>li>a');
        snb.submenuTimeouts = [];
        snb.submenuOpeners = [];
        snb.menuHideTimeout = 20;
        snb.googleMaps = $('#google-maps');
        snb.navTop.on({
            mouseover: function () {
                var submenu = $(this).next('ul');
                if (!submenu.is('ul')) return;
                submenu.slideDown();
                var idx = $(this).parent().index();
                clearTimeout(snb.submenuTimeouts[idx]);
                $(this).addClass('opened');
            }, mouseleave: function () {
                var submenu = $(this).next();
                var idx = $(this).next().parent().index();
                snb.submenuOpeners[idx] = $(this);
                snb.submenuTimeouts[idx] = setTimeout(function () {
                    submenu.slideUp();
                    snb.submenuOpeners[idx].removeClass("opened");
                }, snb.menuHideTimeout);
            }
        });

        snb.submenus = navbar.find(' nav> ul li a').next();

        snb.submenus.on({
            mouseenter: function () {
                var submenu = $(this);
                var idx = submenu.parent().index();
                clearTimeout(snb.submenuTimeouts[idx]);
            },
            mouseleave: function () {
                var submenu = $(this);
                submenu.opener = $(this).prev();
                var idx = submenu.parent().index();
                snb.submenuTimeouts[idx] = setTimeout(function () {
                    submenu.slideUp();
                    snb.submenuOpeners[idx].removeClass('opened');
                }, snb.menuHideTimeout);
            }
        });

        //mobile navigation
        $(function () {
            $('#dl-menu').dlmenu({
                animationClasses: {classin: 'dl-animate-in-1', classout: 'dl-animate-out-3'}
            });
        });

        //login-url

        snb.loginButton = $(".login-url");
        snb.loginFormUl = $('.login-form'); //.add($('.language-switcher')).add($('.social-links'));

        snb.loginButton.on({
            click: function () {
                snb.loginFormUl.fadeToggle();
            }
        });

        snb.body.on({
            click: function(){
                snb.loginFormUl.find('form').fadeIn();
            }
        }, '.show-form');
    };

    $.fn.homeSlider = function (autoplay) {
        snb.homeSlider = {
            "sliderObject": $('.slider-home'),
            "slides": $('.slider-home .slide'),
            "sliderDotsList": $('.slider-home .dots > ul'),
            'activeSlide': 0,
            'numberOfSlides': $('.slider-home .slide').length - 1,
            'showSlide': function (slide) {
                $.each(snb.homeSlider.slides, function (idx, el) {
                    $(el).removeClass("active").fadeOut();
                });
                $.each(snb.homeSlider.sliderNav, function (idx, el) {
                    $(el).removeClass("active");
                });
                $(snb.homeSlider.slides[slide]).addClass('active').fadeIn();
                $(snb.homeSlider.sliderNav[slide]).addClass('active');
                snb.homeSlider.activeSlide = slide;
                snb.homeSlider.checkForLogo();
            },
            checkForLogo: function(){
              var activeSlide = $(snb.homeSlider.slides[snb.homeSlider.activeSlide]);
              if (activeSlide.hasClass('hide-logo')){
                snb.body.addClass('hide-logo-on-slide');
              } else {
                snb.body.removeClass('hide-logo-on-slide');
              }
            },
            startSlider: function () {
                snb.homeSlider.interval =
                    setInterval(function () {
                        if (snb.homeSlider.activeSlide === snb.homeSlider.numberOfSlides) {
                            snb.homeSlider.showSlide(0);
                        } else {
                            snb.homeSlider.showSlide(snb.homeSlider.activeSlide + 1);
                        }
                    }, 5500);
            },
            buildNav: function () {
                $.each(snb.homeSlider.slides, function (idx, el) {
                    var navDot = '<li><span></span></li>';
                    snb.homeSlider.sliderDotsList.append(navDot);
                    snb.homeSlider.sliderDotsList.find('li:first').addClass('active');
                });
                snb.homeSlider.sliderNav = snb.homeSlider.sliderDotsList.find('li');
                snb.homeSlider.sliderNav.on({
                    click: function (e, slideToShow) {
                        var slide = $(this).index();
                        if (!$(this).hasClass("active")) snb.homeSlider.showSlide(slide);
                    }
                });
            },
            stopOnHover: function () {
                snb.homeSlider.sliderObject.on({
                    mouseenter: function () {
                        clearInterval(snb.homeSlider.interval);
                    },
                    mouseleave: function () {
                        snb.homeSlider.startSlider();
                    }
                });
            },
            init: function (autoplay) {
                snb.homeSlider.buildNav();
                if (autoplay) snb.homeSlider.startSlider();
                snb.homeSlider.stopOnHover();
                snb.homeSlider.checkForLogo();
            }
        };

        snb.homeSlider.init(autoplay);
    };

    $.fn.logoSlider = function () {
        snb.logosSlider = {
            "sliders": $('.single-logo-slider'),
            "slideChange": 4500,
            "intervals": [],
            "initLogoSliders": function () {
                snb.logosSlider.sliders.find('li:first-child').addClass('active');
            },
            "startLogoSliders": function () {
                snb.logosSlider.sliders.each(function (idx, el) {
                    snb.logosSlider.intervals[idx] = setInterval(function () {
                        snb.logosSlider.showNextSlide(snb.logosSlider.sliders[idx]);
                    }, snb.logosSlider.slideChange)
                });
            },
            "showNextSlide": function (slider) {
                var handle = $(slider);
                var slides = $(slider).find('li').length;
                var activeChild = handle.find('li.active').index() + 1;
                if (activeChild == slides) {
                    handle.find('li').removeClass('active');
                    handle.find('li:first-child').addClass('active');
                } else {
                    handle.find('li.active').removeClass('active').next().addClass('active');
                }
            },
            "init": function () {
                snb.logosSlider.initLogoSliders();
                snb.logosSlider.startLogoSliders();
            }
        };
        snb.logosSlider.init();

    };

    $.fn.groupTabs = function () {
        snb.groupTabs = $('ul.group-tabs');
        snb.groupContents = $('div.group-contents .tab-content');
        snb.groupTabs.line = $('img.line');


        snb.groupTabs.on('click', 'li > a.tab-link', function (event) {
            var clicker = $(this);
            if (!clicker.hasClass('is-active')) {
                event.preventDefault();
                clicker.parent().siblings().find('a').removeClass('is-active');
                clicker.addClass('is-active');

                var idx = clicker.parent().index();
                snb.groupContents.each(function (ind, el) {
                    if (idx == ind) {
                        $(el).addClass('is-open');
                    } else $(el).removeClass('is-open');
                });
                snb.groupTabs.line.animate({
                    left: clicker.attr('data-line-left')
                }, 500);
            } else {
                event.preventDefault();
            }
        });

        max = 8;
        var rand = Math.floor(Math.random() * (max) + 1);
        snb.groupTabs.find("li:nth-child(" + rand + ") a").trigger('click');


    };

    $.fn.snbHelpers = function () {
        snb.body = $("body");
        snb.mobile = $('html').hasClass('touch');
        $('.parent-form-submit').on({
            click: function () {
                $(this).parents('form').submit();
            }
        });

        $('.subpage-slider').slick({
            dots: false,
            infinite: false,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });

        $('[data-href]').on({
            click: function () {
                var url = $(this).attr('data-href');
                window.location = url;
            }
        });

        //not used
        snb.window = $(window);
        snb.windowWidth = $(window).width();

        snb.window.on({
            resize: function () {
                snb.windowWidth = $(window).width();
                snb.centerVertical();
            },
            load: snb.centerVertical
        });

        snb.activateLoginModal();

    };

    $.fn.timeline = function () {
        snb.timeline = $('#timeline');
        if (snb.timeline.length === 0) return;
        snb.react = $('#width-react');
        snb.mobile = $('html').hasClass('touch');
        snb.mobile ? snb.autoScroll = "" : snb.autoScroll = "onStart";
        snb.timeline.reBuild = function () {
            snb.timeline.containerWidth = $('.subpage-block').width();
            snb.timeline.contentWidth = $('.main-content').width();
            snb.timeline.attr('data-hover', snb.timeline.containerWidth);
            snb.timeline.attr('data-normal', snb.timeline.contentWidth);
            snb.timeline.css('width', snb.timeline.attr('data-normal'));
            snb.react.css('width', snb.timeline.attr('data-normal'));
            snb.mobile = $('html').hasClass('touch');
            snb.timeline.find('.interactive li').off();
            if (snb.mobile)
                snb.timeline.find('.interactive li').on({
                    touchend: snb.showExtraInfo
                });
            if (!snb.mobile)
                snb.timeline.find('.interactive li').on({
                    click: snb.showExtraInfo
                });

            if (snb.mobile) {
                snb.timeline.css('width', snb.timeline.attr('data-hover'));
                snb.react.css('width', snb.timeline.attr('data-hover'));
                $('.tab-content.is-open').css('opacity', 0);
            }
        };
        snb.timeline.reBuild();
        //snb.autoScroll;
        snb.timeline.smoothDivScroll({
            //autoScrollingMode: snb.autoScroll,
            autoScrollingInterval: 30,
            touchScrolling: snb.mobile,
            setupComplete: snb.checkTheLegendStartOnVisible,
            autoScrollingDirection: "backAndForth",
            hotSpotScrollingStep: 20,
            hotSpotScrollingInterval: 30

        });
        snb.timeline.basicElements = snb.timeline.find('.interactive ul li');
        snb.window.on({
            resize: snb.timeline.reBuild,
            scroll: snb.launchTimeline
        });

        if (!snb.mobile) {
            snb.timeline.hover(function (e) {
                snb.timeline.css('width', snb.timeline.attr('data-hover'));
                snb.react.css('width', snb.timeline.attr('data-hover'));
                $('.tab-content.is-open').removeClass('is-open');
                snb.timeline.smoothDivScroll('stopAutoScrolling');
            }, function (e) {
                //snb.timeline.css('width', snb.timeline.attr('data-normal'));
                //$('.tab-content.is-open').css('opacity', 1);
                snb.timeline.smoothDivScroll('startAutoScrolling');
            });
        }

        snb.timeline.basicElements.hover(function () {
            $(this).find('.hover').fadeIn();
        }, function () {
            $(this).find('.hover').fadeOut();
        });

        snb.timeline.find('.interactive svg').each(function (idx, el) {
            var width = parseInt($(el).attr('width').replace('px', ''));
            var height = parseInt($(el).attr('height').replace('px', ''));
            //$(el).attr('width', parseInt((width * 1.4)) + 'px');
            //$(el).attr('height', parseInt((height * 1.4)) + 'px');
        });

        snb.legend = $("#i-am-legend");
        snb.legendContent = snb.legend.find(".legend-content > div");
        snb.legend.on({
            mouseover: snb.stopTimelineEvents,
            mouseenter: snb.stopTimelineEvents
        });

        snb.legend.find('>ul>li').on({
            click: function (e) {
                var clicker = $(this);
                if (clicker.hasClass('active')) return;
                var idx = clicker.index();
                snb.legendContent.removeClass('active');
                clicker.siblings().removeClass('active');
                $(snb.legendContent[idx]).addClass('active');
                clicker.addClass('active');
            }
        });
        var delayedHide;
        snb.legend.find('.close').on({
            click: function () {
                if (snb.legend.hasClass('hide-me')) {
                    snb.legend.removeClass('hide-me');
                    return false;
                }
                snb.legend.addClass('hide-me');
                snb.timeline.smoothDivScroll('startAutoScrolling');
                delayedHide = setTimeout(function () {
                    snb.legend.addClass('hide-me');
                    snb.timeline.smoothDivScroll('stopAutoScrolling');
                }, 60);
            }
        });

        snb.timeline.find('.photos li').hover(function (e) {
            $('.info.active').removeClass('active');
        });

        snb.groupTabs.on('click', 'li > a.tab-link', function (event) {
            snb.timeline.css('width', snb.timeline.attr('data-normal'));
            snb.react.css('width', snb.timeline.attr('data-normal'));
        });

    };

    $.fn.sponsoringMembers = function () {
        snb.sponsoringSlick = $('#sponsoring-members');
        if (snb.sponsoringSlick.length === 0) return false;
        snb.sponsorInterval = 4000;
        snb.sponsoringNav = $('#sponsoring-nav li');
        snb.sponsoringSlick.slick({
            dots: false,
            infinite: false,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            touch: false,
            pauseOnHover: true

        });
        snb.sponsoringNav.on({
            click: function (e, obj) {
                var clicker = $(this);
                snb.sponsoringNav.removeClass('active');
                clicker.addClass('active');
                snb.sponsoringSlick.slick('slickGoTo', clicker.index());
                if (typeof obj == "undefined") {
                    clearInterval(snb.sponsoringInterval);
                    //if (!snb.sponsoringSlick.find('.slick-active .scrollTo').visible(false) && snb.mobile) {
                    $('html, body').animate({
                        scrollTop: snb.sponsoringSlick.offset().top
                    }, 500);
                    //}
                }
            }
        });

        snb.sponsoringInterval = setInterval(function () {
            snb.selectRandomSponsor();
        }, snb.sponsorInterval);

        snb.selectRandomSponsor = function () {
            var max = snb.sponsoringNav.length;
            var rand = Math.floor(Math.random() * (max));
            $(snb.sponsoringNav.get(rand)).trigger('click', {'fake': true});
        };

        snb.sponsoringSetInterval = function () {
            snb.sponsoringInterval = setInterval(function () {
                var max = snb.sponsoringNav.length;
                var rand = Math.floor(Math.random() * (max));
                $(snb.sponsoringNav.get(rand)).trigger('click', true);
            }, snb.sponsorInterval);
        };

        snb.sponsoringSlick.on({
            mouseenter: function () {
                clearInterval(snb.sponsoringInterval);
            }
            //mouseleave: function () {
            //    snb.sponsoringSetInterval();
            //}
        });

        snb.window.on({
            load: snb.selectRandomSponsor
        });

        snb.body.on({
            click: function () {
                $(this).parent('.member-info').toggleClass('opened');
            }
        }, '.close-more');
        snb.sponsoringSlick.on("afterChange", function(event, slick, currentSlide){
            var currentSlide = $(".slick-slide.slick-active");
            var maxH = $('.member-info').css('max-height').replace('px', '');
            if (currentSlide.find('.member-info > section').outerHeight() <= parseInt(maxH)) {
                currentSlide.find('.close-more').hide();
            } else {
                currentSlide.find('.close-more').show();
            }
        });
        snb.sponsoringSlick.on("beforeChange", function(event, slick, currentSlide){
            $('.member-info').removeClass('opened');
        });

    };

    $.fn.cooperatingOrganizations = function () {
        snb.cooperatingNavButtons = $('#cooperating-nav li');
        if (snb.cooperatingNavButtons.length == 0) return;
        //snb.openModalID('#organization-popup-1');
        snb.cooperatingNavButtons.on({
            click: function () {
                var id = $(this).attr('data-id');
                snb.openModalID('#organization-popup-' + id);
            }
        });
    };

    $.fn.snbStrcture = function () {
        snb.structure = $('#structure');
        if (snb.structure.length === 0) return false;
        snb.groupSmall = snb.window.width() < 640;
        snb.structure.on({
            click: function () {
                var button = $(this);
                var temp = button.attr('data-open');
                var open = $(temp);
                var dataOpened = $('.opened');
                var buttonOpened = $('.show-data');
                if ($('.data').is(':animated')) return false;
                if (button.is('.show-data')) {
                    dataOpened.slideUp(function () {
                        dataOpened.removeClass('opened');
                        buttonOpened.removeClass('show-data');
                    });
                    return false;
                }
                if (buttonOpened.length !== 0 && dataOpened.length !== 0) {
                    dataOpened.slideUp(function () {
                        dataOpened.removeClass('opened');
                        buttonOpened.removeClass('show-data');
                        button.trigger('click');
                    });
                    return false;
                }
                //if (buttonOpened.length !== 0) return false;
                if (open.hasClass('opened')) {
                    open.slideUp(function () {
                        open.removeClass('opened');
                        button.removeClass('show-data');
                    })
                } else {
                    open.slideDown(function () {
                        open.addClass('opened');
                        button.addClass('show-data');
                    })
                }

            }
        }, '[data-open]');

        snb.structure.on({
            closeLevels: function () {
                $(this).find('.show-data').removeClass('show-data');
                $(this).find('.data-open').removeClass('data-open').slideUp();
            }
        });

        snb.structureTabs = $('#structure-tabs');

        snb.watchStructureHeight = setInterval(snb.structureHeightMatch, 1);
        setTimeout(function () {
            clearInterval(snb.watchStructureHeight);
        }, 500);

        snb.structure.on({
            click: function () {
                snb.structureTabs.removeClass('taller-office');
                var clicker = $(this);
                if (!snb.groupSmall) snb.watchStructureHeight = setInterval(snb.structureHeightMatch, 1);
                setTimeout(function () {
                    clearInterval(snb.watchStructureHeight);
                }, 510);
                if (clicker.hasClass('show-data')) {
                    clicker.removeClass('show-data');
                    clicker.next().slideUp().removeClass('open');
                    return false;
                }
                snb.structureTabs.find('.full-office').removeClass('show-data');
                clicker.addClass('show-data');
                snb.structureTabs.find('.content.open').slideUp();
                clicker.next().slideDown().addClass('open');
                snb.structure.experts.removeClass('hoverPrev');
            }
        }, '.full-office');

        snb.structureGroups = $('#workingGroups');

        snb.structureGroups.on({
            click: function () {
                var button = $(this);
                var opened = $('.show-group');
                var temp = button.attr('data-show');
                var open = $(temp);
                if ($('.workingDescription').is(":animated")) return false;
                if (button.hasClass('show-data')) {
                    open.slideUp();
                    button.removeClass('show-data');
                    return false;
                }
                $('[data-show]').removeClass('show-data');
                button.addClass('show-data');
                if (opened.length === 0) {
                    open.slideDown().addClass("show-group");
                    snb.scrollToOffset(open.offset().top);
                } else {
                    opened.removeClass('show-group').slideUp(function () {
                        open.slideDown().addClass("show-group");
                        if ($('.group-only').length === 0)  return false;
                        snb.scrollToOffset(open.offset().top);
                    });
                }

            }
        }, '[data-show]');
        snb.structure.experts = snb.structure.find('#experts');
        snb.structure.experts.on({
            click: function () {
                var openOffice = $('.full-office.show-data');
                var experts = $(this);
                if (openOffice.length !== 0) {
                    openOffice.trigger('click');
                    setTimeout(function () {
                        experts.addClass('hoverPrev');
                        experts.animate({height: 280}, 300);
                        snb.structureTabs.addClass('taller-office');
                    }, 515);
                    return false;
                }
                experts.toggleClass('hoverPrev');
                if (experts.hasClass('hoverPrev')) {
                    experts.animate({height: 280}, 300);
                    snb.structureTabs.addClass('taller-office');
                } else {
                    snb.structureTabs.removeClass('taller-office');
                    setTimeout(function () {
                        experts.animate({height: snb.structureTabs.height()}, 300);
                    }, 300);
                }
            }
        });

        $('#phouse').on({
            click: function () {
                $(this).next().slideToggle();
                $(this).toggleClass('show-phouse')
            }
        });

        //snb.structureExperts();
        //snb.window.on({
        //    resize: snb.structureExperts
        //});


        /*

         .hover(function(){
         $('#office-full').addClass('hide-arrows');
         var openOffice = $('.full-office.show-data');
         if (openOffice.length !== 0 ) {
         openOffice.trigger('click');
         }
         }, function(){
         $('#office-full').removeClass('hide-arrows');
         });

         */

    };

    $.fn.googleMaps = function () {
        if (snb.googleMaps.length == 0) return;

        snb.googleMaps.gmap3({
            marker: {
                address: "al. Niepodległości 18, 02-653 Warszawa",
                data: 'SNB',
                options: {
                    icon: new google.maps.MarkerImage('resources/google-marker.png')
                }
            },
            overlay: {
                address: "al. Niepodległości 18, 02-653 Warszawa",
                options: {
                    content: "<div class='contact-marker'></div>",
                    offset: {
                        x: -100,
                        y: -40
                    }
                }
            },
            map: {
                address: "al. Niepodległości 18, 02-653 Warszawa ",
                options: {
                    zoom: 18,
                    mapTypeControl: false,
                    mapTypeId: "style1",
                    mapTypeControlOptions: {
                        mapTypeIds: ["style1"]
                    },
                    navigationControl: false,
                    scrollwheel: true,
                    streetViewControl: false
                }
            },
            styledmaptype: {
                id: "style1",
                options: {
                    name: "Style 1"
                },
                styles: [
                    {
                        stylers: [
                            {saturation: -87},
                            {hue: "#0066ff"}
                        ]
                    }
                ]
            }
        });
    };

    $.fn.modalQuiz = function () {
        var surveyJSON = {
            "answered": 0,
            "showInfo": 0,
            "showQuestion": 0,
            "convention": 0
        };
        var expires = 30; // default expire time
        snb.surveyButtons = $('[data-saction]');
        snb.saveChoiceButtons = $('[data-key]');


        snb.surveyCookie = Cookies.get('survey') !== undefined ? JSON.parse(Cookies.get('survey')) : surveyJSON;

        if (snb.surveyCookie.answered == 1 && snb.surveyCookie.showInfo == 0) return;

        console.info(snb.surveyCookie);

        snb.surveyButtons.on({
            click: function () {
                var action = $(this).attr('data-saction');
                snb[action]();
            }
        });

        snb.saveChoiceButtons.on({
            click: function () {
                var data = {
                    "key": $(this).attr('data-key'),
                    "value": $(this).attr('data-value')
                };
                snb.setSurveyCookieKey(data.key, data.value);
            }
        });

        snb.showQuestion = function () {
            snb.openModalID('#modal-quiz-question');
        };

        snb.expireTomorrow = function () {
            expires = 1;
        };

        snb.setSurveyCookieKey = function (key, value) {
            surveyJSON["answered"] = 1;
            surveyJSON[key] = parseInt(value);
            Cookies.set('survey', JSON.stringify(surveyJSON), {path: "/", expires: expires});
            console.info(JSON.stringify(surveyJSON));
        };

        //conditional launching question
        if (snb.surveyCookie.answered == 1) {
            if (snb.surveyCookie.convention == 1) snb.openQuizModal('#modal-thanks');
        } else {
            snb.openQuizModal('#modal-survey');
        }


    };

    $.fn.adjustWindowHeight = function () {
        snb.subpageBlock = $('.single-article');
        if (snb.subpageBlock.length == 0) return;
        snb.subpageHeight = snb.subpageBlock.height();
        if (snb.body.height() < snb.window.height()) {
            snb.subpageBlock.css({
                "min-height": snb.window.height() - ($('.top-bar').height() + $('section.footer').height()) + 10
            });
        }

    };

    $.fn.floatingStatutorySidebar = function () {
        snb.statutList = $('.sidebar-statutlist');
        if (snb.statutList.length == 0) return;
        handleStatutoryList = function () {
            if (snb.windowWidth >= 640 && snb.window.scrollTop() > 320) {
                snb.body.addClass('floating-statutory');
            } else {
                snb.body.removeClass('floating-statutory');
            }
        };

        snb.window.on({
            scroll: handleStatutoryList,
            resize: handleStatutoryList
        });


    };

    // helper functions

    snb.structureExperts = function () {
        $('.expert-content').innerWidth(snb.structure.find('.main').innerWidth());
        snb.groupSmall = snb.window.width() < 768;
    };

    snb.structureHeightMatch = function () {
        $('#experts').height(snb.structureTabs.height());
    };

    snb.scrollToOffset = function(offset){
        $('html, body').animate({
            scrollTop: offset + 'px'
        }, 500);
    };

    snb.showExtraInfo = function (e) {
        var info = $(this).find('.info');
        if (info.length === 0) return;
        e.stopPropagation();
        $('.info').each(function () {
            $(this).removeClass('active');
        });
        info.addClass('active');
        console.info(info);
    };

    snb.stopTimelineEvents = function (e) {
        e.stopPropagation();
    };

    snb.checkTheLegendStartOnVisible = function () {
        if (snb.timeline.visible(false))
            snb.timeline.smoothDivScroll('startAutoScrolling');

        setInterval(function () {
            //if (snb.legend.hasClass('hide-me') && snb.timeline.smoothDivScroll('getScrollerOffset') == 0) snb.legend.removeClass('hide-me');
        }, 30);
    };

    snb.launchTimeline = function () {
        if (snb.timeline.visible(false) && !snb.timeline.hasClass('seen')) {
            snb.timeline.smoothDivScroll('startAutoScrolling');
            snb.timeline.width(snb.timeline.attr('data-hover'));
            snb.react.css('width', snb.timeline.attr('data-hover'));
            $('.tab-content.is-open').removeClass('is-open');
        }

    };

    snb.centerVertical = function () {
        snb.tableCenters = $('.center-vertical');
        snb.tableCenters.each(function () {
            var temp = $(this).parents('.parent').find('.parent-height').height();
            $(this).height(temp);
        });
    };

    snb.activateLoginModal = function () {
        $("#modal-login").on("change", function () {
            if ($(this).is(":checked")) {
                $("body").addClass("modal-open");
            } else {
                $("body").removeClass("modal-open");
            }
        });

        $(".modal-fade-screen, .modal-close").on("click", function () {
            $(".modal-state:checked").prop("checked", false).change();
            $("body").removeClass("modal-open");
        });

        $(".modal-inner").on("click", function (e) {
            e.stopPropagation();
        });
    };

    snb.openModalLogin = function () {
        $("#modal-login").prop('checked', true);
        $("body").addClass("modal-open");
    };

    snb.openModalID = function (modalID) {
        $('.modal > input').prop('checked', false);
        $(modalID).prop('checked', true);
        snb.body.addClass("modal-open");
    };

})(jQuery);
